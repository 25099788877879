<template>
  <div class="list-page">
    <HeaderTabs
      :tabs="tabs"
      @click="handleTabClick"
    >
    </HeaderTabs>
    <div v-if="name === '订单'">
      <div class="card"  v-for="(item, index) in list" :key="item.id" @click="$router.push({path: `/order/routerpath?id=${list[index].id}&sapcode=${list[index].sapOrderCode}`})">
        <div class="title">您的订单{{item.sapOrderCode}}已发货，请做好接货准备。</div>
        <div class="tip" v-if="!item.isRead || '0' == item.isRead"/>
        <div class="time">{{item.outTime}}</div>
      </div>
    </div>
    
    <div v-if="name === '任务'">
      <div class="card2" v-for="item in list" :key="item.id">
        <div class="title">{{item.messName}}</div>
        <div class="time">{{item.createTime}}</div>
      </div>
    </div>
    <div v-if="name === '来款'">
      <div class="card"  v-for="(item,index) in list" :key="item.id" @click="onBankAdd(item.id,index)">
        <div class="tip" v-if="!item.isRead || '0' == item.isRead"/>
        <div class="title" style="font-weight: bold">汇款未入账</div>
        <div class="title">公司收到<span style="color: red">{{item.name}}</span>汇款{{item.money}}元，因汇款账号未维护，汇款暂未入账。请点击本条消息维护汇款账号，维护后款项自动入账。</div>
        <div class="time">{{item.createTime}}</div>
      </div>
    </div>
    
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
    

   
  </div>
</template>
<script>
import HeaderTabs from '@/components/header/headerTabs/index.vue'
import {fetchOrderLogisticsNoticeList} from "@/api/order";
import {fetchBankMoneyList,bankMoneyRead} from "@/api/bank";
import InfiniteLoading from 'vue-infinite-loading'
import {fetchNotification} from "@/api/notification";

export default {
  components: {
    HeaderTabs,
    InfiniteLoading,
  },
  data() {
    return {
      name: "订单",
      tabs: [{ title: "订单" }, { title: "任务" }, { title: "来款" }],
      pageNo: 1,
      pageSize: 10,
      total: 10,
      list: []
    }
  },
 
 
  methods: {

    /**
     * 获取通知列表
     */
    async getNotification() {
      let response;
      if (this.name === '订单') {
        response = await fetchOrderLogisticsNoticeList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
        });
      } else if (this.name === '来款') {
        response = await fetchBankMoneyList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
        });
      } else {
        response = await fetchNotification({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
        });
      }
        this.list = this.list.concat(response.data || []);
        this.total = (response.page && response.page.total) || 0;
    },

    /**
     * 重置列表
     */
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },

    /**
     * tab切换
     */
    handleTabClick(tab) {
      console.log(tab)
      this.name = tab;
      this.resetList()
    },

    /**
     * 上拉刷新
     */
    async infiniteHandler($state) {
      await this.getNotification();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    
    onBankAdd(id,index) {
      bankMoneyRead({id: id}).then(() => {
        this.list[index].isRead = '1';
        this.$router.push({path: "/bank/child/add"});
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.list-page {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  background: #FAFBFC;
  z-index: 10;
  padding-bottom:92px;
  overflow: hidden;
  /* min-height: 100vh;
   background-color: #FAFBFC;
   padding-bottom: 32px;
   box-sizing: border-box;*/
  .card {
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    margin: 12px auto 0;
    padding: 15px 12px;
    text-align: left;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #818181;
      margin-top: 7px;
    }
    .tip{
      position:absolute;
      color:white;
      z-index: 99;
      font-size: 12px;
      font-weight: bold;
      background-color: red;
      width: 10px;
      height: 10px;
      line-height: 20px;
      left : 85%;
      text-align: center;
      -webkit-border-radius: 10px;
      border-radius: 10px;

    }
  }


  .card2 {
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    margin: 12px auto 0;
    padding: 15px 12px;
    text-align: left;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #818181;
      margin-top: 7px;
    }
  }
}
</style>