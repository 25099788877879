<template>
  <div class="home-page" style="height: 100%">
    <div class="cart-header">
      <div class="cart-title">购物车</div>
      <div class="cart-clear" @click="clearCart">删除</div>
    </div>
    <div style="height: 85%; overflow: auto;">
      <div class="product" v-for="(item, index) in cartList" :key="index">
        <div class="cart-list">
          <div class="cart-item" >
            <Checkbox @click="selectProduct(index)" v-model="item.check" style="padding: 0 10px;" />
            <img style="width: 70px;height: 90px;" :src="checkImgExists(item.materialPhoto)" alt="商品图片">
            <div style="width: 65%">
              <div class="cart-name">{{ item.materialName }}</div>
              <div style="display: flex">
                <div class="cart-info">
                  <div class="cart-price">价格：{{ item.price || 0}}</div>
                  <div class="cart-price">{{item.factoryValue}}/{{item.tranModeValue}}</div>
                </div>
                <div class="cart-count">
                  <div class="cart-btn" @click="decreaseCount(index)">-</div>
                  <div class="cart-num">{{ item.num }}</div>
                  <div class="cart-btn" @click="increaseCount(index)">+</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-summary">
      <div class="cart-total"><span style="font-size: 16px;color: #969799">合计：</span>{{ totalPrice }}</div>
      <div class="cart-checkout" @click="checkout">结算</div>
    </div>


    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>


import {Checkbox, Dialog, Loading} from 'vant';

import {fetchShopCaList,changeShopCarMaterial,delShopCarMaterial} from "@/api/product";
import {fetchMaterialPrice, fetchProductList} from "@/api/select";
import {getDealerId} from "@/utils/user";

export default {
  components: {
    Checkbox,
    Loading,
  },
  watch: {
    '$route'(to, from) {
      console.log(to);
      console.log(from.name);
      if (from.name != 'OrderAddOrderFirst' && from.name != 'productCar') {
        this.getList();
        this.factory = null;
        this.tranMode = null;
        this.size = 0;
      }

    },
  },
  data() {
    return {
      isLoading: false,
      factory: null,
      tranMode: null,
      ids: null,
      produceList: null,
      size: 0,
      cartList: [
        { id: 1, name: "史丹利-塔硫-160528-50咖啡色三安", price: "50元", image: "https://shidanli.oss-cn-qingdao.aliyuncs.com/api/C0DE7F1470224B64AF79C43AA8E45935.jpg", count: 1 },
        { id: 2, name: "史丹利-塔硫-160528-50咖啡色三安", price: "30元", image: "https://shidanli.oss-cn-qingdao.aliyuncs.com/api/C0DE7F1470224B64AF79C43AA8E45935.jpg", count: 2 },
        { id: 3, name: "史丹利-塔硫-160528-50咖啡色三安", price: "80元", image: "https://shidanli.oss-cn-qingdao.aliyuncs.com/api/C0DE7F1470224B64AF79C43AA8E45935.jpg", count: 1 }
      ],
      checked: false
    };
  },
  computed: {
    totalPrice() {
      return this.cartList.reduce((total, item) => {
        return total + item.num * parseFloat(item.price || 0);
      }, 0);
    }
  },
  deactivated() {
    // this.getList()
  },
  activated() {
    // this.getList()
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      fetchShopCaList().then(res => {
        this.cartList = res.data;
        console.log(res);
      });
    },
    
    increaseCount(index) {
      this.cartList[index].num++;
      changeShopCarMaterial({id: this.cartList[index].id,num: this.cartList[index].num});
    },
    decreaseCount(index) {
      if (this.cartList[index].num > 1) {
        this.cartList[index].num--;
        changeShopCarMaterial({id: this.cartList[index].id,num: this.cartList[index].num});
      }
    },

    selectProduct(index) {
      if (this.cartList[index].check) {  //选中
        if (this.size >= 7) {
          this.$toast('选择的物料不能大于7种')
          this.cartList[index].check = false;
          return;
        }
        if (this.factory && this.tranMode) {
          if (this.factory != (this.cartList[index].factory || '10') || this.tranMode != (this.cartList[index].tranMode || '20')) {
            this.$toast('请选择同类发货方式及同一个发货公司的订单')
            this.cartList[index].check = false;
            return;
          }
        }
        
        this.size = ++this.size;
        this.factory = this.cartList[index].factory || '10';
        this.tranMode = this.cartList[index].tranMode || '20';
        
        this.isLoading = true;
        fetchMaterialPrice({
          "dealerId": getDealerId(),
          "factory": this.cartList[index].factory || '10',
          "tranMode": this.cartList[index].tranMode || '20',
          "materialCode": this.cartList[index].materialId,
        }).then(res => {
          this.isLoading = false;
          this.cartList[index].price = res.data.price;
        }).catch(err => {
          this.isLoading = false
          this.$toast(err.message)
        });
      } else { //取消选中
        this.cartList[index].price = 0;
        this.size = --this.size;
        if (this.size <= 0) {
          this.factory = null;
          this.tranMode = null;
        }
      }
      
      this.ids = '';
      this.cartList.forEach(item => {
        if (item.check) {
          this.ids = this.ids + item.id + ",";
        }
      });
      if (this.ids) {
        this.ids = this.ids.slice(0,this.ids.length-1)
      }
    },


    async clearCart() {
      if (!this.ids) {
        this.$toast('至少选择一条数据进行删除')
        return;
      }
      await Dialog.confirm({
        message: '确定要删除所选记录？',
      }).then(() => {
        delShopCarMaterial({id:this.ids}).then(() => {
          this.getList();
          this.$toast('删除成功');
          this.factory = null;
          this.tranMode = null;
          this.size = 0;
        });
      }).catch(() => {
        // on cancel
      });
    },

    async checkout() {
       this.produceList = [];
       var checkProduct = this.cartList.filter(item => item.check);
       if (null == checkProduct || checkProduct.length <= 0) {
         this.$toast('至少选择一条数据进行结算')
         return;
       }
      this.isLoading = true;
      for (var i=0;i<checkProduct.length;i++) {
        var item = checkProduct[i];
        console.log(item)
        const param = {
          "dealerId": getDealerId(),
          "factory": item.factory || '10',
          "tranMode": item.tranMode || '20',
          "materialCode": item.materialId,
        }
        //查物料是否绑定
        await fetchProductList(param).then(res => {
          if (res.data && res.data.length > 0) {
            res.data[0].applyNumber = item.num;
            this.produceList.push(res.data[0]);
            if (i === (checkProduct.length-1)) {
              console.log(this.produceList)
              this.isLoading = false;
              this.$router.push({
                name: 'OrderAddOrderFirst', 
                params: {
                  seller: 'self',
                  settlementProduct:this.produceList,
                  orderSource:'1',
                  factory: item.factory || '10',
                  tranMode: item.tranMode || '20',
                  factoryValue: item.factoryValue || '临沭',
                  tranModeValue: item.tranModeValue || '汽运',
                }
              })
            }
          } else {
            this.$toast('物料：'+item.materialName+'已经不存在，无法结算');
            this.isLoading = false;
            return;
          }
        }).catch(err => {
          this.$toast(err.message)
          this.isLoading = false;
        });
      }
    },

      
    
    checkImgExists(imgurl) {
      var imgObj = new Image() // 判断图片是否存在
      imgObj.src = imgurl
      // 存在图片
      if (imgObj.fileSize > 0 || (imgObj.width > 0 && imgObj.height > 0)) {
        return imgurl;
      } else {
        return 'https://shidanli.oss-cn-qingdao.aliyuncs.com/product/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230320151908.png';
      }
    }
    
  }
};
</script>

<style>

body{
  height: 100%;
}
html{
  height: 100%;
}

#app {
  height: 100%;
}

.loading-container {
  width: 100%;
  height: 100%;
  background: #7c7a7a;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
}

.product {
  margin: 7px 7px;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 10px;
  box-sizing: border-box;
}
.cart {
  padding: 10px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-title {
  font-size: 20px;
  font-weight: bold;
  padding:10px 20px;
}

.cart-clear {
  color: red;
  padding:10px 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cart-item img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.cart-info {
  flex: 1;
  text-align: left;
}

.cart-name {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 3px;
  padding-top: 8px;
  text-align: left;
}

.cart-price {
  color: #969799;
  font-size: 13px;
  padding-bottom: 3px;

}

.cart-count {
  display: flex;
  align-items: center;
}

.cart-btn {
  width: 20px;
  height: 20px;
  border: 1px solid #969799;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cart-num {
  margin: 0 10px;
  font-size: 15px;
}

.cart-summary {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.cart-total {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 14px;
  margin-right: 25px;
}

.cart-checkout {
  background-color: #cd0520;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 80px;
  height: 35px;
  line-height: 35px;
  margin-top: 10px;
  margin-right: 20px;

}
</style>