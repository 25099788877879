<template>
  <div class="home-page">

   

    <Swipe :autoplay="60000" class="swipe">
      <SwipeItem v-for="(image, index) in bannerData" :key="index">
        <img class="img"  :src="image"/>
      </SwipeItem>
    </Swipe>


    <div class="product">
      <div  class="van-sku-header van-hairline--bottom">
        <div  class="van-sku-header__goods-info" style="text-align: left;padding-right: 0;padding-bottom: 0;">


          <div  class="van-sku-header-item">
            <span  style="color: red; font-size: 22px; font-weight: bold;">{{price}}</span><span  style="color: #646566; font-size: 12px;padding-left: 10px;">   如显示暂无价格,请选择发货公司及运输方式</span>
          </div>
          <div  class="van-sku__goods-price" style="padding-top: 10px;">
            <span  class="van-sku__price-num" style="color: rgb(44, 44, 44); font-weight: bold; font-size: 18px;">{{product.materialName}}</span>
          </div>
        </div>
      </div>
      
      <div class="van-sku-body">
        <div class="van-sku-messages" style="padding-bottom: 0;padding-top: 15px;">
          <div class="van-sku-row van-hairline--bottom" style="display: flex;margin: 0;padding: 0 10px;height: 40px;">
            <div class="van-sku-row__title" style="width: 80px;line-height: 40px;padding-right: 20px;font-size: 18px;color: #646566">发货公司:</div>
            <span class="van-sku-row__item van-sku-row__item--active" style="width: auto;margin: auto 8px" @click="onClickFactory">
              <div class="van-sku-row__item-name">{{factoryText}}</div>
            </span>
          </div>

          <div class="van-sku-row van-hairline--bottom" style="display: flex;margin: 0;padding: 10px;height: 40px;">
            <div class="van-sku-row__title" style="width: 80px;line-height: 40px;padding-right: 20px;font-size: 18px;color: #646566">运输方式:</div>
            <span class="van-sku-row__item" @click="onClickTranMode('20')" :class="tranMode === '20' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
              <div class="van-sku-row__item-name">汽运</div>
            </span>
            <span class="van-sku-row__item" @click="onClickTranMode('30')" :class="tranMode === '30' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
              <div class="van-sku-row__item-name">船运</div>
            </span>
            <span class="van-sku-row__item" @click="onClickTranMode('10')" :class="tranMode === '10' ? 'van-sku-row__item--active' : ''" style="width: 60px;margin: auto 8px">
              <div class="van-sku-row__item-name">铁运</div>
            </span>
          </div>
          
          <div class="van-sku-messages__cell-block" style="padding-top: 10px;padding-bottom: 10px;">
            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;font-size: 18px;">
              <div class="van-cell__title van-field__label" style="width: 50px;margin-right: 5px;">
                <span>品牌:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.brandName}}</span>
                </div>
              </div>
            </div>

            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;font-size: 18px;">
              <div class="van-cell__title van-field__label" style="width: 50px;margin-right: 5px;">
                <span>工艺:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.materialClassName}}</span>
                </div>
              </div>
            </div>

            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;font-size: 18px;">
              <div class="van-cell__title van-field__label" style="width: 50px;margin-right: 5px;">
                <span>配比:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.matchEq}}</span>
                </div>
              </div>
            </div>

            <div class="van-cell  van-cell--borderless van-field" style="padding: 4px 18px;font-size: 18px;">
              <div class="van-cell__title van-field__label" style="width: 50px;margin-right: 5px;">
                <span>包重:</span>
              </div>
              <div class="van-cell__value van-field__value">
                <div class="van-field__body">
                  <span >{{product.bagWeightName}}</span>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
      
    </div>

    <div style="margin: 20px;">
      —— <span style="font-weight: bold">产品详情</span> ——
    </div>

    <div class="product">
      <p style="text-align: left;padding:2px 8px;margin-top: 3px;" v-html="product.materialContent"></p>
      
    </div>

    <GoodsAction>
      <GoodsActionIcon @click="onClickCollect()" icon="star" :color="isCollect === '0' ? 'rgb(200, 200, 200)' : '#ff5000'" text="收藏" />
      <GoodsActionIcon @click="$router.push({name: 'productCar'})" icon="cart-o" text="购物车" />
      <GoodsActionButton @click="onClickAddCar" type="warning" text="加入购物车" />
      <GoodsActionButton @click="onClickAddCar" type="danger" text="立即购买" />
    </GoodsAction>


    <Car :showAddCar.sync="showAddCar" :product="product" @hidden="onHiddenCar"></Car>


    <Popup v-model="showFactory" position="bottom" :style="{ height: '30%' }">
      <Picker title="发货公司" show-toolbar :columns="factoryList" input-align="right"
          @confirm="onConfirmFactory"
          @cancel="onCancelFactory"
      />
      
    </Popup>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
    
  </div>
</template>

<script>
import * as user from '@/utils/user';
import Car from '../../../components/car/index';

import {collectProduct, fetchMaterialInfo} from "@/api/product";
import {fetchDictList} from "@/api/common";



import {Swipe,SwipeItem,Popup,Picker,GoodsAction,GoodsActionIcon,GoodsActionButton,Loading   } from 'vant';
import {fetchMaterialPrice} from "@/api/select";
import {getDealerId} from "@/utils/user";
export default {
  components: {
    Swipe,
    SwipeItem,
    Popup,
    Picker,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Car,
    Loading
  },
  
  data() {
    return {
      nick: user.getNickName(),
      isLoading: false,
      homeData: {},
      role: user.getUserType(),
      factoryText: '点击选择发货公司',
      factory: null,
      tranMode: '20',
      price: '暂无价格',
      searchTotal: null,
      active: 0,
      activeKey: 0,
      bannerData:[
        
      ],
      productList:[
        {
          id:"1",
          scpic:"https://shidanli.oss-cn-qingdao.aliyuncs.com/api/C0DE7F1470224B64AF79C43AA8E45935.jpg",
          subcname:"史丹利-塔硫-160528-40-蓝色版面图案（高钾蓝）",
          content:"<p>   </p><p>适用范围：</p><p><br></p><p>广泛适用于玉米、小麦、水稻、大豆、棉花、花生、蔬菜等多种农作物。</p><p><br></p><p>参考用量：</p><p><br></p><p>1)做基肥时要深施15厘米以下，每亩40-60kg。也可视当地实际情况而定。</p><p><br></p><p>2)做追肥可条施或穴施，每亩用量30-40kg，注意深施覆土，与植株保持10-15厘米为宜，谨防烧苗或烂根。</p><p><br></p><p>产品特点：</p><p><br></p><p>1)国内首创，并获得国家专利。</p><p><br></p><p>2)养分均衡，肥效持久，利用率高。</p><p><br></p><p>3)含生物腐植酸，改良土壤，培肥地力。</p><p><br></p><p>4)能促使作物根系发达，壮苗早发，抗旱、抗病、抗倒伏。</p><p><br></p><p>注意事项：</p><p><br></p><p>1)结合当地土壤、气候、施肥习惯确定施肥方法、用量及施肥时期。</p><p><br></p><p>2)含缩二脲，使用不当会对作物造成伤害。</p><p><br></p><p></p>"
        }
      ],
     
      showAddCar: false,
      showFactory: false,
      factoryList: [],
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          
        ],
        price: '22.00', // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        messages: [
          
        ],
        hide_stock: false // 是否隐藏剩余库存
      },

      materialId: null,
      isCollect: '0',
      product: null,
      messageConfig: {
        // 数据结构见下方文档
      },
    }
  },
  activated() {
    this.factoryText = '点击选择发货公司';
    this.factory = null;
    this.tranMode = '20';
    this.price = '暂无价格';
    
    this.materialId = this.$route.params.id;
    this.isCollect = this.$route.params.isCollect;
    this.getMaterialInfo();

   fetchDictList({
      "type": 'eb_factory'
    }).then(response => {
      response.data.forEach(item => {
        this.factoryList.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    })
    
    
    
  },
 
  methods: {

    async getMaterialInfo() {
      if (!this.materialId) {
        this.materialId = this.product.materialCode;
      }
      const res = await fetchMaterialInfo({
        "materialCode": this.materialId
      });
      this.product = res.data;
      this.bannerData = [];
      this.bannerData.push(res.data.largerUrl)
      this.bannerData.push(res.data.smallUrl)
      console.log(this.product)
    },

    onClickTranMode(val) {
      this.tranMode = val;
      if (this.factoryText != '点击选择发货公司') {
        this.getMaterialPrice();
      }
      
    },

    onClickCollect() {
      collectProduct({"materialId": this.product.materialCode}).then(() => {
        this.isCollect = this.isCollect === '0' ? '1' : '0';
      });
    },
   
   
    onClickAddCar() {
      this.showAddCar = true;
      console.log('打开购物车')
    },
   
    onClickFactory() {
      this.showFactory = true;
      console.log('选择发货公司')
    },
    onConfirmFactory(e) {
      this.factory = e.value;
      this.factoryText = e.text;
      this.showFactory = false;
      
      this.getMaterialPrice();
    },
    
    
    getMaterialPrice() {
      this.isLoading = true;
      fetchMaterialPrice({
        "dealerId": getDealerId(),
        "factory": this.factory || '10',
        "tranMode": this.tranMode || '20',
        "materialCode": this.materialId,
      }).then(res => {
        this.isLoading = false;
        this.price = '￥:'+res.data.price;
      }).catch(err => {
        this.isLoading = false
        this.$toast(err.message)
      });
    },
    onCancelFactory() {
      this.showFactory = false;
    },

    onHiddenCar() {
      this.showAddCar = false;
    },
    
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #FAFBFC;
  z-index: 10;
  padding-bottom: 92px;
  box-sizing: border-box;

  .loading-container {
    width: 100%;
    height: 100%;
    background: #7c7a7a;
    opacity: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
  
 

  .swipe {
    .img {
      width: 97%;
      height: 360px;
    }
  }

  .product {
    margin: 0px 7px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    box-sizing: border-box;
  }

}
</style>
