<template>
  <div class="home-page">

    <router-link to="/map" class="map-mask">
      <div class="map-container" id="mapContainer"></div>
    </router-link>

    <div class="nav">
      <div class="nav-header">
        <span class="title">我的渠道</span>
        <router-link to="/map">
          <div class="team-button">
            <img class="team-icon" src="../../assets/images/home/map.png" />
            <span class="team-label">渠道详情</span>
          </div>
        </router-link>
      </div>
      <div class="nav-hr"></div>
      <div class="nav-row">
        <router-link to="/terminalOutlets">
          <NavItem icon="zdwd" label="终端网点" :value="zdwdv"/>
        </router-link>
        <router-link to="/bigGrowers">
          <NavItem icon="zzdh" label="种植大户" :value="zzdhv"/>
        </router-link>
        <router-link to="/potentialCustomers">
          <NavItem icon="qzkh" label="潜在客户" :value="qzkhv"/>
        </router-link>
        <router-link to="/emptyArea">
          <NavItem icon="kbqy" label="空白区域" :value="kbqyv"/>
        </router-link>
      </div>
      <div class="nav-row">
        <router-link to="/agriculturalTechnology">
          <NavItem icon="njh" label="农技会" :value="njhv"/>
        </router-link>
        <router-link to="/demonstrationField">
          <NavItem icon="sft" label="示范田" :value="sftv"/>
        </router-link>
        <router-link to="/bill/manage">
          <NavItem icon="zkgl" label="账款管理" :value="zkglv"/>
        </router-link>
        <router-link to="/message/batch">
          <NavItem icon="dxqf" label="短信群发" :value="dxqfv"/>
        </router-link>
      </div>
      

      <div class="nav-header" style="padding-top: 20px;">
        <span class="title">渠道分析</span>
      </div>
      <div class="nav-hr"></div>
      <div class="nav-row">
          <div class="nav-item" @click="onClickReport('channeldata')">
            <img class="icon" src="../../assets/images/home/order.png" />
            <div class="label">渠道变化</div>
          </div>

          <div class="nav-item" @click="onClickReport('terminalval')">
            <img class="icon" src="../../assets/images/home/sales.png" />
            <div class="label">终端分析</div>
          </div>

          <div class="nav-item" @click="onClickReport('terminallist')">
            <img class="icon" src="../../assets/images/home/price.png" />
            <div class="label">乡镇终端</div>
          </div>

          <div class="nav-item" @click="onClickReport('plantrank')">
            <img class="icon" src="../../assets/images/home/account.png" />
            <div class="label">作物覆盖</div>
          </div>

       
      </div>
    </div>










    <!-- tabbar -->
    <Tabbar />
  </div>
</template>

<script>
import * as user from '@/utils/user';
import { fetchHomeData } from '@/api/home';
import Tabbar from '../../components/common/tabbar/index';
import NavItem from '../home/components/navItem';
import {initMap} from "@/utils/map";

export default {
  components: {
    Tabbar,
    NavItem
  },
  data() {
    return {
      nick: user.getNickName(),
      homeData: {},
      role: user.getUserType(),
      zdwdv: "0:0",
      zzdhv: "0:0",
      qzkhv: "0:0",
      kbqyv: "0:0",
      njhv: "0:0",
      sftv: "0:0",
      zkglv: "0",
      dxqfv: "0"
    }
  },
  async activated() {
    const res = await fetchHomeData();
    this.homeData = res.data;
    const num = res;
    this.zdwdv= num.data.kjTerminal + ":" + num.data.dlTerminal;
    this.zzdhv= num.data.kjBigfarmers + ":" + num.data.dlBigfarmers;
    this.qzkhv= num.data.kjPotential +":"+ num.data.dlPotential;
    this.kbqyv= num.data.kjBlank;
    this.njhv= num.data.kjTechconference + ":" + num.data.dlTechconference;
    this.sftv= num.data.kjModelField + ":" + num.data.dlModelField;
    this.zkglv= num.data.fundPool;
    this.dxqfv= num.data.sendMessage;
    //置空查询条件
    window.localStorage.setItem("shidanli-filter", "");
    window.localStorage.setItem("agroDetailFilterAncestors", "");
  },
  async mounted() {
    initMap('mapContainer', { currentLocation: true });
  },
  methods: {
    
    onClickReport(val) {
      // var baseUrl = 'https://ebs.shidanli.cn/h5report';
      var baseUrl = 'http://ebstest.shidanli.cn/crm/h5report';
      window.location.href=baseUrl +"/"+val+ "?dealerId=" + user.getDealerId() + "&roleType=dealer";
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: #FAFBFC;
  z-index: 10;
  padding-bottom: 92px;
  box-sizing: border-box;

  .nav {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto 12px auto;
    width: 351px;
    //height: 270px;
    //height: 335px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    box-sizing: border-box;
    .nav-row {
      padding: 2px 29px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 12px;
        .icon {
          width: 48px;
          height: 48px;
        }
        .label {
          margin-top: 8px;
          width: 48px;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #818181;
        }
        .value {
          margin-top: 6px;
          width: 40px;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: #CD011F;
        }
      }
    }
    .nav-header {
      width: 331px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /*border-bottom: 1px solid #EAEAEA;*/
      padding: 5px 10px;
      margin-top: 5px;
      .title {
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: #2C2C2C;
      }
      .team-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .team-icon {
          width: 10px;
          height: 12px;
          margin-right: 2px;
        }
        .team-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #CD001F;
        }
      }
    }
    .nav-hr {
      width: 335px;
      border-bottom: 1px solid #eaeaea;
      margin: 0 auto;
    }
  }

  .map-container {
    width: 351px;
    height: 250px;
    margin: 0 auto 0 auto;
    background-color: #ddd;
  }
  .map-mask {
    position: relative;
    display: block;
    &:after {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
  }
}
</style>
